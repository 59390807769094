<template>
  <div v-if="crowdfundingInfo">
    <div class="d-flex mb-16">
      <h1 class="headline--md text-bold text-uppercase">BISS Program</h1>

      <div
        v-if="currentUser && $auth('Page', currentUser, $accessRule.ProgramUpdate).canView()"
        class="d-flex align-start form-switch-wrapper"
      >
        <p class="mr-4">Go live on app:</p>

        <label class="form-switch">
          <input type="checkbox" v-model="programStatus" @click.prevent="onConfirmToggleProgramStatusOpen = true" />
          <span></span>
        </label>
      </div>
    </div>

    <div v-if="plans">
      <img :src="crowdfundingInfo.imageUrl" width="360" class="d-block" alt="crowdfunding program image" />

      <div class="table-wrapper table-wrapper--fluid mt-16">
        <table class="table table--draggable">
          <thead class="align-middle">
            <tr>
              <th></th>
              <th></th>
              <th>Fund Name</th>
              <th>
                Interest Rate
                <br />(%)
              </th>
              <th>Min. Amount</th>
              <th>Max. Amount</th>
              <th>
                Min. Staked
                <br />(TOKO)
              </th>
              <th>
                Hold Period
                <br />(Months)
              </th>
              <th></th>
            </tr>
          </thead>

          <draggable v-model="plans" tag="tbody" v-bind="dragOptions" handle=".drag-handler" @update="onDragUpdate()">
            <tr v-for="plan in plans" :key="plan.id" class="link--clone">
              <td></td>
              <td>
                <span class="drag-handler">
                  <toko-burger-icon />
                </span>
              </td>
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
              >
                <!-- TODO: implement status -->
                <!-- <span
                  v-if="activated(plan.status)"
                  class="tag tag--success">Active</span>
                <span
                  v-else
                  class="tag tag--gray">Deactive</span> -->
                <p>{{ plan.title }}</p>
              </router-link>
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
                >{{ plan.rate | rate_percent }}</router-link
              >
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
                >{{ plan.minDepositAmount | number_with_commas }}</router-link
              >
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
                >{{ plan.maxDepositAmount | number_with_commas }}</router-link
              >
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
                >{{ plan.minTokoAmount | number_with_commas }}</router-link
              >
              <router-link
                :to="{ name: 'CrowdfundingPlan', params: { programId: crowdfundingInfo.id, planId: plan.id } }"
                tag="td"
                >{{ plan.period }}</router-link
              >
              <td></td>
            </tr>
          </draggable>
        </table>
      </div>

      <!-- TODO: implement set interest rate -->
      <!-- <router-link
        class="btn btn--primary mt-16"
        :to="{ name: 'CrowdfundingPlanNew', params: { programId: crowdfundingInfo.id } }"
        tag="button">Add New Fund
      </router-link> -->
    </div>

    <confirm-dialog
      v-if="onConfirmToggleProgramStatusOpen"
      :open.sync="onConfirmToggleProgramStatusOpen"
      confirmButtonText="Save"
      confirmButtonClass="text-purple"
      @confirm="onUpdateProgram"
    >
      <p v-if="programStatus">
        Are you sure to disable this program ?<br />
        User will not be able to stake anymore.
      </p>
      <p v-else>Are you sure to enable this program ?</p>
    </confirm-dialog>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import updateProgram from './mixins/update-program'
import formatActivate from '@/mixins/format-activate'
import toggleLoading from '@/mixins/toggle-loading'
const TokoBurgerIcon = () => import('@/ui/toko-icons/TokoBurger')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: 'plans',
        disabled: false
      },
      plans: null
    }
  },

  mixins: [updateProgram, formatActivate, toggleLoading],

  components: {
    draggable,
    ConfirmDialog,
    TokoBurgerIcon
  },

  computed: {
    ...mapGetters('crowdfunding', ['getCrowdfundingPlansOrdered']),

    ...mapState('crowdfunding', ['crowdfundingInfo']),

    planIdsOrdered() {
      return this.plans.map(plan => plan.id)
    }
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingPlans']),

    onDragUpdate() {
      this.$lStorage.set('crowdfundingPlanIdsOrdered', this.planIdsOrdered)
    },

    getProgramDataUpdate() {
      return {
        id: this.crowdfundingInfo.id,
        status: this.programStatus ? this.status.deactive : this.status.active
      }
    },

    onUpdateProgram() {
      this.toggleLoading()
      this.updateProgram(this.getProgramDataUpdate())
        .then(() => {
          this.programStatus = this.crowdfundingInfo.status === this.status.active
        })
        .finally(() => {
          this.onConfirmToggleProgramStatusOpen = false
          this.toggleLoading()
        })
    }
  },

  async created() {
    try {
      await this.getProgramList()
      await this.getCrowdfundingPlans()
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.plans = this.getCrowdfundingPlansOrdered
    this.programStatus = this.crowdfundingInfo.status === this.status.active
    this.toggleLoading()
  }
}
</script>
